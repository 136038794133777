<template>
  <v-card rounded="lg" class="px-11 pt-14 pb-9" elevation="1">
    <v-row class="hero-box">
      <v-col cols="12" class="flex-item-left">
        <h2 class="themepurple">MY ACCOUNT</h2>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-tabs color="#020659">
                <v-tab>General Info</v-tab>
                <!-- <v-tab>My Organization</v-tab> -->
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field color="#020659" label="First Name" readonly
                          v-model="generalInfo.firstName"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field color="#020659" label="Last Name" readonly
                          v-model="generalInfo.lastName"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="E-Mail" color="#020659" readonly
                          v-model="generalInfo.email"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field color="#020659" label="Organization Name" readonly
                          v-model="generalInfo.organizationName"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" v-if="OrgHasGrants && CanViewGrants && GrantsApproved">
                        <v-btn :to="{
                          name: 'planning-grant-post-approval',
                        }" depressed color="#F28705" class="white--text my-5">VIEW MY GRANT</v-btn>
                      </v-col>
                      <v-col cols="12" v-if="generalInfo.IsCapacityBuildingSpecialist">
                        <v-btn @click="organizationProfileEditDialogVisible = true" color="#F28705"
                          class="white--text">Edit Capacity Building Specialist Profile</v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-btn @click="confirmChangePasswordDialog = true" color="#F28705" class="white--text"
                          target="__blank">Change Password</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="organizationProfileEditDialogVisible" max-width="1700">
      <OrganizationProfileEdit v-if="organizationProfileEditDialogVisible"
        @closed="organizationProfileEditDialogVisible = false"></OrganizationProfileEdit>
    </v-dialog>
    <v-dialog v-model="confirmChangePasswordDialog" max-width="600">
      <v-card>
        <v-card-title color="#F28705" class="text-h5 grey lighten-2">
          Confirm Navigation
        </v-card-title>

        <v-card-text>
          You are now leaving the Impact HQ website. A new tab will open for our authentication partner, allco. Once you
          change your password, return to the Impact HQ site and log in with your new password.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" color="error" @click="confirmChangePasswordDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="openAllcoAccount">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import OrganizationProfileEdit from "../components/popups/OrganizationProfileEdit.vue";

export default {
  async beforeRouteEnter(to, from, next) {
    var currentUser = await Vue.$userService.current();
    var grants = await Vue.$grantFormService.find({
      pagination: {
        page: 1,
        pageSize: 10
      },
      filters: {
        OrganizationId: currentUser.organization.id
      }
    });
    next((vm) => {
      vm.OrgHasGrants = grants.data.data.length > 0;
      vm.CanViewGrants = currentUser.CanViewGrants;
      vm.GrantsApproved = grants.data.data.filter((grant) => grant.attributes.isApproved).length > 0;
      vm.generalInfo = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        organizationName: currentUser.organization.name,
        IsCapacityBuildingSpecialist: currentUser.IsCapacityBuildingSpecialist
      };
    });
  },
  name: "account",
  components: {
    OrganizationProfileEdit,
  },
  data() {
    return {
      CanViewGrants: false,
      OrgHasGrants: false,
      GrantsApproved: false,
      organizationProfileEditDialogVisible: false,
      confirmChangePasswordDialog: false,
      generalInfo: {
        firstName: null,
        lastName: null,
        email: null,
        organizationName: null,
        IsCapacityBuildingSpecialist: false
      }
    };
  },
  methods: {
    async saveGeneralInfo() {
      try {
        var result = await this.$validator.validate("general.*");
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.sending = true;

      try {
        await Vue.$userService.save(this.user);
        Vue.$eventBus.$emit("notifyUser", "General Info Saved");
      } finally {
        this.sending = false;
      }
    },

    openAllcoAccount() {
      window.open('https://account.allco.app/myAccount/security', '_blank');
      this.confirmChangePasswordDialog = false;
    },

    async savePassword() {
      try {
        var result = await this.$validator.validate("password.*");
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.sending = true;

      try {
        await Vue.$userService.save({
          id: this.user.id,
          password: this.password,
        });
        Vue.$eventBus.$emit("notifyUser", "Password Saved");
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>