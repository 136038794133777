<template>
  <v-app :class="{ landing: $route.name === 'landing', 'site-background': $route.name !== 'landing'  }">
    <v-container fluid>
      <v-main>
        <router-view />
        <v-snackbar v-model="snackbar.show" bottom>{{ snackbar.message }}</v-snackbar>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import Vue from 'vue';

export default {
  name: "App",

  data: () => ({
    snackbar: {
      position: "center",
      duration: 4000,
      message: null,
      show: false
    }
  }),
  created() {
    Vue.$eventBus.$on("notifyUser", this.notifyUser);
  },
  destroyed() {
    Vue.$eventBus.$off("notifyUser", this.notifyUser);
  },
  methods: {
    notifyUser: function(message) {
      this.snackbar.message = message;
      this.snackbar.show = true;
    }
  }
};
</script>

