export default {
    install: (Vue) => {
        Vue.$eventBus = new Vue();

        Vue.axios.interceptors.response.use(response => {
          return response;
        }, async error => {
          var message = 'Uh oh, something went wrong!';
          if (error.response.status === 400) {
            message = error.response.data.error.message
          }
          if (error.response.status === 429) {
            message = "Too Many Requests. Please Wait!"
          }
          Vue.$eventBus.$emit('notifyUser', message);
          Vue.$eventBus.$emit('showSending', false);
          return Promise.reject(error);
        });
    }
};