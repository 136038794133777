<template>
  <div id="hero-section_login" class="blue-background">
    <v-container class="form-pinch">
      <v-row class="text-center text-md-left">
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="12" md="6" class="pl-5 justify-lg-end align-lg-end">
              <router-link to="/">
                <img :src="logo" width="200" height="auto" class="logo"/>
              </router-link>
              <h3 class="tagline">
                Where nonprofits connect, equip, &amp; grow.
              </h3>
              <img
                class="d-none d-md-block pt-lg-5 mt-lg-5"
                src="../../assets/images/map/endsection.png"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

h3.tagline {
  font-size: 35px;
  font-weight: 500;
  color: #171b5b;
  margin: 35px 0;
}

.form-pinch {
  padding: 10% 10% 0 10%;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .no-left-pad-md {
    padding-left: 0;
  }
}
</style>

<script>
import logo from "../../assets/images/logo.png";

export default {
  data() {
    return {
      logo: logo,
    };
  },
};
</script>
