<template>
  <v-card
    elevation="12"
    class="pa-4 black--text"
    style="background: white !important; width: 100%"
  >
    <v-card-title>
      <h2>Forgot Password</h2>
    </v-card-title>
    <v-card-text class="no-left-pad-md">
      <v-form v-on:submit.prevent="submitForgotPassword">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Email"
              name="email"
              type="text"
              outlined
              dense
              :disabled="sending"
              v-validate.disable="'required'"
              :error-messages="errors.collect('Email')"
              data-vv-name="Email"
              required
              autocomplete="off"
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              :disabled="sending"
              type="submit"
              :loading="sending"
              class="main-btn"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss"></style>

<script>
import logo from "../../assets/images/logo.png";
import Vue from "vue";

export default {
  name: "forgotPassword",
  data() {
    return {
      logo: logo,
      email: null,
      sending: false,
    };
  },
  methods: {
    async submitForgotPassword() {
      let result;
      try {
        result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      if (!result) {
        Vue.$eventBus.$emit("notifyUser", "Fix the invalid fields");
        return false;
      }

      this.sending = true;

      try {
        await Vue.$userService.forgot(this.email);

        Vue.$eventBus.$emit("notifyUser", "Please check your email");

        this.$router.push("/auth/login");
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
