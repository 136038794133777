// content resource service
export default {
    install: (Vue) => {
      //private
      const route = process.env.VUE_APP_API_BASE_URL + '/api/organizations';
  
      //public
      Vue.$organizationService = {
        find(params) {
          return Vue.axios.get(route, {
            params: params
          });
        },
  
        findOne(key, params) {
          return Vue.axios.get(`${route}/${key}`, {
            params: params
          });
        },

        currentOrganization() {
          return Vue.axios.get(`${route}/current`);
        },

        async updateEndorsements(organization) {
          await Vue.axios.put(`${route}/${organization.id}`, {
            data: {
              serviceEndorsements: organization.serviceEndorsements
            }
          })
        },

        async save(organization) {
          var formData = new FormData();

          if (organization.profilePicture instanceof File) {
            formData.append('files.profilePicture', organization.profilePicture, organization.profilePicture.name);
            delete organization.profilePicture;
          }

          formData.append('data', JSON.stringify(organization));

          if (organization.id) {
            return (await Vue.axios.put(`${route}/${organization.id}`, formData, {
              header: {
                'Content-Type': 'multipart/form-data'
              }
            })).data.data;
          }

          var createdOrganization = (await (Vue.axios.post(route, formData, {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }))).data.data;

          organization.id = createdOrganization.id;

          if (organization.users) {
            await Vue.axios.put(`${route}/${organization.id}`, {
              data: {
                users: {
                  connect: organization.users
                }
              }
            })
          }

          return organization;
        },
        async autocomplete(val) {
          return (await Vue.axios.get(`${route}/autocomplete`, {
            params: {
              q: val
            }
          })).data.data;
        }
      };
    }
  };