export default {
  install: (Vue) => {
    let currentUser = null
    let currentUserPromise = null;

    //public
    Vue.$userService = {
      isLoggedIn() {
        var jwt = Vue.$cookies.get('jwt')
        if (jwt) {
          Vue.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${jwt}`;
        }
        return !!jwt;
      },
      async current() {
        if (!this.isLoggedIn()) {
          return null;
        }
        var user = currentUser;
        if (!user) {
          var allcoAccessToken = Vue.$cookies.get('allcoAccessToken')
          currentUserPromise = currentUserPromise || Vue.axios.get(process.env.VUE_APP_API_BASE_URL + '/api/users/me', {
            params: {
              allcoAccessToken
            }
          });
          currentUser = (await currentUserPromise).data;
          user = currentUser;
        }
        return user;
      },
      async localLogin(identifier, password) {
        var localLoginInfo = await Vue.axios.post(process.env.VUE_APP_API_BASE_URL + '/api/auth/local', {
          identifier,
          password
        });
        Vue.$cookies.set('jwt', localLoginInfo.data.jwt);
      },
      register(registration) {
        return Vue.axios.post(
          process.env.VUE_APP_API_BASE_URL + "/api/auth/local/register",
          registration
        );
      },
      async login(email, password) {
        const res = await Vue.axios.post(process.env.VUE_APP_API_BASE_URL + '/api/auth/local', {
          identifier: email,
          password,
        });

        if (res.data.jwt) {
          Vue.$cookies.set('jwt', res.data.jwt);
        }

        if (res.user) {
          window.sessionStorage.setItem("user", JSON.stringify(res.user));
        }

        return res;
      },
      async forgot(email, captchaCode) {
        return await Vue.axios.post(
          process.env.VUE_APP_API_BASE_URL + "/api/auth/forgot-password",
          {
            email,
            captchaCode,
          }
        );
      },
      reset(password, passwordConfirmation, code) {
        return Vue.axios.post(process.env.VUE_APP_API_BASE_URL + "/api/auth/reset-password", {
          password,
          passwordConfirmation,
          code,
        });
      },
    };
  }
};