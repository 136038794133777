<template>
  <v-card elevation="12" class="black--text pa-4" style="background: white !important; width: 100%">
    <v-card-title>
      <h2>Reset Password</h2>
    </v-card-title>
    <v-form v-on:submit.prevent="resetPassword">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="password"
              label="New Password"
              name="password"
              ref="password"
              outlined
              dense
              type="password"
              autocomplete="off"
              :disabled="sending"
              :error-messages="errors.collect('Password')"
              v-validate="'required|min:8|verify_password'"
              data-vv-name="Password"
              required
              v-model="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Confirm Password"
              type="password"
              :disabled="sending"
              outlined
              dense
              v-validate="'required|confirmed:password'"
              data-vv-validate-on="change"
              :error-messages="errors.collect('Confirm Password')"
              data-vv-name="Confirm Password"
              data-vv-as="Password"
              required
              v-model="passwordConfirm"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="sending"
          type="submit"
          :loading="sending"
          class="main-btn"
        >
          Reset Password
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<style scoped lang="scss">
</style>

<script>
import Vue from 'vue';

export default {
  name: 'resetPassword',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.code = to.query.code;
    });
  },
  data() {
    return {
      code: null,
      sending: false,
      password: null,
      passwordConfirm: null
    };
  },
  methods: {
    async resetPassword() {
      let result;
      try {
        result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      if (!result) {
        Vue.$eventBus.$emit('notifyUser', 'Fix the invalid fields');
        return false;
      }

      this.sending = true;

      try {
        await Vue.$userService.reset(this.password, this.passwordConfirm, this.code);
      } catch {
        this.sending = false;
        Vue.$eventBus.$emit('notifyUser', 'An unexpected error has occured');
        return;
      }

      Vue.$eventBus.$emit('notifyUser', 'The password has been reset');

      this.$router.push('/auth/login');
    }
  }
};
</script>
