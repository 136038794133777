import Vue from "vue";
import VueRouter from "vue-router";
import TrainingEvent from '../views/News/SingleTraningEvent.vue'
import Account from '../views/Account.vue'
import AuthenticationBase from "../components/auth/AuthenticationBase.vue";
import Login from "../components/auth/Login.vue";
import Registration from "../components/auth/Registration.vue";
import Completion from "../components/auth/Completion.vue";
import Confirmation from "../components/auth/Confirmation.vue";
import ForgotPassword from "../components/auth/ForgotPassword.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/Home/Landing.vue"),
    meta: {
      allowAnonymous: true
    },
  },
  {
    path: "/registrationConfirmation",
    name: "registrationConfirmation",
    component: () => import("../views/RegistrationConfirmation.vue"),
    meta: {
      allowAnonymous: true
    },
  },
  {
    path: '/auth',
    name: 'authBase',
    component: AuthenticationBase,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          allowAnonymous: true
        }

      },
      {
        path: 'registration',
        name: 'Registration',
        component: Registration,
        meta: {
          allowAnonymous: true
        }

      },
      {
        path: 'completion',
        name: 'Completion',
        component: Completion,
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'confirmation',
        name: 'Confirmation',
        component: Confirmation,
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'resetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          allowAnonymous: true
        }
      },
    ]
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      allowAnonymous: true
    },
    children: [   
      {
        path: '/account',
        name: 'Account',
        component: Account
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Home/Dashboard.vue"),
      },
      {
        path: "/industry",
        name: "industry",
        component: () => import("../views/News/IndustryNews.vue"),
      },
      {
        path: "/industry/:slug",
        name: "Industry",
        component: () => import("../views/News/SingleIndustryNews.vue"),
      },
      {
        path: "/training-event",
        name: "training-event",
        component: () => import("../views/News/TraningEvent.vue"),
      },
      {
        path: "/training/:slug",
        name: "Training",
        component: TrainingEvent,
      },
      {
        path : "/racial-equity",
        name : "racial-equity",
        component : ()=> import("../views/LearnByTopic/RacialEquity.vue"),
      },
      {
        path : "/technology",
        name : "technology",
        component : ()=> import("../views/LearnByTopic/Technology.vue"),
      },
      {
        path : "/fund-development",
        name : "fund-development",
        component : ()=> import("../views/LearnByTopic/FundDevelopment.vue"),
      },
      {
        path : "/healthcare",
        name : "healthcare-integration",
        component : ()=> import("../views/LearnByTopic/HealthCareIntegration.vue"),
      },
      {
        path : "/grant-depot",
        name : "grant-depot",
        component : ()=> import("../views/SelfGuidedResources/GrantDepot.vue"),
      },
      {
        path: "/grant/:slug",
        name: "Grant",
        component: () => import("../views/SelfGuidedResources/SingleGrant.vue"),
      },
      {
        path : "/capacity-building",
        name : "capacity-building",
        component : ()=> import("../views/OrgSupport/CapacityBuildingSpecialists.vue"),
      },
      {
        path: "/impact-fund",
        name: "impact-fund",
        component: () => import("../views/OrgSupport/ImpactFund.vue"),
      },
      {
        path: "/planning-grant-post-approval",
        name: "planning-grant-post-approval",
        component: () => import("../views/OrgSupport/PlanningGrantPostApproval.vue"),
      },
      {
        path : "/capacity-central",
        name : "capacity-central",
        component : ()=> import("../views/SelfGuidedResources/CapacityCentral.vue"),
      },
      {
        path : "/nonprofit-junction",
        name : "nonprofit-junction",
        component : ()=> import("../views/SelfGuidedResources/NonProfitJunction.vue"),
      },
      {
        path : "/skill-based-volunteers",
        name : "skill-based-volunteers",
        component : ()=> import("../views/OrgSupport/SkillBasedVolunteers.vue"),
      },
      {
        path : "/funder-directory",
        name : "funder-directory",
        component : ()=> import("../views/OrgSupport/FunderDirectory.vue"),
      },
      {
        path: "/new-impact/:slug",
        name: "newOnImpact",
        component: () => import("../views/News/SingleNewImpact.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      }
    }
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(async (to, from, next) => {
  if (Vue.$userService.isLoggedIn()) {
    if (to.meta.allowAnonymous) {
      return next('/dashboard');
    }
    return next();
  } 
  
  if (!to.meta.allowAnonymous) {
    return next(false);
  }

  return next();
});

export default router;
